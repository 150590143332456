"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
//@ts-ignore
const config_NODE_ENV_1 = require("@src/app/config-NODE_ENV");
const bmi_percentile_calculator_1 = require("@trifoia/bmi-percentile-calculator");
const defaultValues = {
    gender: 'male',
};
class BodyfatcalculatorCtrl {
    constructor($scope, $filter) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.unit = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}').unit || 'metric';
        this.values = Object.assign(Object.assign({}, defaultValues), JSON.parse(localStorage.getItem(`${this.constructor.name}_values`) || '{}'));
        this.bfpTable = [
            {
                key: 'essential',
                title: this.$filter('translate')('Essential fat'),
                female: [10, 13],
                male: [2, 5]
            },
            {
                key: 'athletes',
                title: this.$filter('translate')('Low body fat'),
                female: [14, 20],
                male: [6, 13]
            },
            {
                key: 'fitness',
                title: this.$filter('translate')('Moderate body fat'),
                female: [21, 24],
                male: [14, 17]
            },
            {
                key: 'average',
                title: this.$filter('translate')('Elevated body fat'),
                female: [25, 31],
                male: [18, 24]
            },
            {
                key: 'obese',
                title: this.$filter('translate')('Obesity'),
                female: [32, 100.1],
                male: [25, 100.1],
            },
        ];
        this.jpIdealTable = [
            {
                age: [20, 25],
                female: [17.7, 18.4],
                male: [8.5, 10.5],
            },
            {
                age: [25, 30],
                female: [18.4, 19.3],
                male: [10.5, 12.7],
            },
            {
                age: [30, 35],
                female: [19.3, 21.5],
                male: [12.7, 13.7],
            },
            {
                age: [35, 40],
                female: [21.5, 22.2],
                male: [13.7, 15.3],
            },
            {
                age: [40, 45],
                female: [22.2, 22.9],
                male: [15.3, 16.4],
            },
            {
                age: [45, 50],
                female: [22.9, 25.2],
                male: [16.4, 18.9],
            },
            {
                age: [50, 55.0000000001],
                female: [25.2, 26.3],
                male: [18.9, 20.9],
            },
        ];
        if (config_NODE_ENV_1.config.debug) {
            this.values = Object.assign(Object.assign({}, this.values), { gender: 'female', age: 30, height: 178, height_feet: 6, height_inch: 2, weight: 70, weight_pound: 165, neck: 50, neck_feet: 0, neck_inch: 18, waist: 96, waist_feet: 0, waist_inch: 35, hip: 70, hip_feet: 0, hip_inch: 35 });
        }
        this.$scope.$watch('$ctrl.unit', (unit) => {
            let options = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}');
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify(Object.assign(Object.assign({}, options), { unit })));
        });
    }
    $onInit() {
    }
    setUnit(unit) {
        this.unit = unit;
        this.values = {
            gender: this.values.gender,
            age: this.values.age,
        };
        this.result = undefined;
    }
    submitForm($form) {
        if ($form.$valid) {
            let result = {};
            try {
                let height_inches = 0;
                let neck_inches = 0;
                let waist_inches = 0;
                let hip_inches = 0;
                if (this.unit == 'imperial') {
                    this.values.weight = 0.453592 * this.values.weight_pound;
                    this.values.height = this.values.height_feet * 30.48 + this.values.height_inch * 2.54;
                    this.values.hip = this.values.hip_feet * 30.48 + this.values.hip_inch * 2.54;
                    this.values.waist = this.values.waist_feet * 30.48 + this.values.waist_inch * 2.54;
                    this.values.neck = this.values.neck_feet * 30.48 + this.values.neck_inch * 2.54;
                    height_inches = 12 * this.values.height_feet + this.values.height_inch;
                    neck_inches = 12 * this.values.neck_feet + this.values.neck_inch;
                    waist_inches = 12 * this.values.waist_feet + this.values.waist_inch;
                    hip_inches = 12 * this.values.hip_feet + this.values.hip_inch;
                    result.bmi = (0, bmi_percentile_calculator_1.english)(this.values.weight_pound, height_inches, this.values.gender == 'male' ? 'm' : 'f', this.values.age * 12);
                }
                else if (this.unit == 'metric') {
                    this.values.weight_pound = 2.20462262185 * this.values.weight;
                    this.values.height_feet = Math.floor(this.values.height / 30.48);
                    this.values.height_inch = ((this.values.height / 30.48) - this.values.height_feet) * 12;
                    this.values.hip_feet = Math.floor(this.values.hip / 30.48);
                    this.values.hip_inch = ((this.values.hip / 30.48) - this.values.hip_feet) * 12;
                    this.values.waist_feet = Math.floor(this.values.waist / 30.48);
                    this.values.waist_inch = ((this.values.waist / 30.48) - this.values.waist_feet) * 12;
                    this.values.neck_feet = Math.floor(this.values.neck / 30.48);
                    this.values.neck_inch = ((this.values.neck / 30.48) - this.values.neck_feet) * 12;
                    height_inches = 12 * this.values.height_feet + this.values.height_inch;
                    neck_inches = 12 * this.values.neck_feet + this.values.neck_inch;
                    waist_inches = 12 * this.values.waist_feet + this.values.waist_inch;
                    hip_inches = 12 * this.values.hip_feet + this.values.hip_inch;
                    result.bmi = (0, bmi_percentile_calculator_1.metric)(this.values.weight, this.values.height / 100, this.values.gender == 'male' ? 'm' : 'f', this.values.age * 12);
                }
                // console.debug(
                //     result,
                //     this.values,
                //     height_inches,
                //     neck_inches,
                //     waist_inches,
                //     hip_inches,
                // )
                if (this.values.gender == 'male') {
                    switch (this.unit) {
                        case "imperial": {
                            result.bfp = 86.010 * Math.log10(waist_inches - neck_inches) - 70.041 * Math.log10(height_inches) + 36.76;
                            break;
                        }
                        case "metric": {
                            result.bfp = 495 / (1.0324 - 0.19077 * Math.log10(this.values.waist - this.values.neck) + 0.15456 * Math.log10(this.values.height)) - 450;
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    if (this.values.age >= 20) {
                        result.bfpBmi = 1.20 * result.bmi.bmi + 0.23 * this.values.age - 16.2;
                    }
                    else {
                        result.bfpBmi = 1.51 * result.bmi.bmi - 0.70 * this.values.age - 2.2;
                    }
                    if (result.bfp >= 2) {
                        if (result.bfp > 100) {
                            result.bfp = 100;
                        }
                        if ((result.bfp >= 2) && (result.bfp < 6)) {
                            result.bfpSector = 'essential';
                            result.bfpSectorTitle = this.$filter('translate')('Essential fat');
                            result.bfpStyleLeft = `${(result.bfp - 2) * 100 / (6 - 2)}%`;
                        }
                        else if ((result.bfp >= 6) && (result.bfp < 14)) {
                            result.bfpSector = 'athletes';
                            result.bfpSectorTitle = this.$filter('translate')('Low body fat');
                            result.bfpStyleLeft = `${(result.bfp - 6) * 100 / (14 - 6)}%`;
                        }
                        else if ((result.bfp >= 14) && (result.bfp < 18)) {
                            result.bfpSector = 'fitness';
                            result.bfpSectorTitle = this.$filter('translate')('Moderate body fat');
                            result.bfpStyleLeft = `${(result.bfp - 14) * 100 / (18 - 14)}%`;
                        }
                        else if ((result.bfp >= 18) && (result.bfp < 25)) {
                            result.bfpSector = 'average';
                            result.bfpSectorTitle = this.$filter('translate')('Elevated body fat');
                            result.bfpStyleLeft = `${(result.bfp - 18) * 100 / (25 - 18)}%`;
                        }
                        else if (result.bfp >= 25) {
                            result.bfpSector = 'obese';
                            result.bfpSectorTitle = this.$filter('translate')('Obesity');
                            result.bfpStyleLeft = `${(result.bfp - 25) * 100 / (100 - 25)}%`;
                        }
                    }
                }
                else if (this.values.gender == 'female') {
                    switch (this.unit) {
                        case "imperial": {
                            result.bfp = 163.205 * Math.log10(waist_inches + hip_inches - neck_inches) - 97.684 * Math.log10(height_inches) - 78.387;
                            // result.bfp = 495 / (1.29579 - 0.35004 * Math.log10(this.values.waist + this.values.hip - this.values.neck) + 0.22100 * Math.log10(this.values.height)) - 450
                            // console.log(
                            //     495 / (1.29579 - 0.35004 * Math.log10(this.values.waist + this.values.hip - this.values.neck) + 0.22100 * Math.log10(this.values.height)) - 450
                            // )
                            break;
                        }
                        case "metric": {
                            result.bfp = 495 / (1.29579 - 0.35004 * Math.log10(this.values.waist + this.values.hip - this.values.neck) + 0.22100 * Math.log10(this.values.height)) - 450;
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    if (this.values.age >= 20) {
                        result.bfpBmi = 1.20 * result.bmi.bmi + 0.23 * this.values.age - 5.4;
                    }
                    else {
                        result.bfpBmi = 1.51 * result.bmi.bmi - 0.70 * this.values.age + 1.4;
                    }
                    if (result.bfp >= 10) {
                        if (result.bfp > 100) {
                            result.bfp = 100;
                        }
                        if ((result.bfp >= 10) && (result.bfp < 14)) {
                            result.bfpSector = 'essential';
                            result.bfpSectorTitle = this.$filter('translate')('Essential fat');
                            result.bfpStyleLeft = `${(result.bfp - 10) * 100 / (14 - 10)}%`;
                        }
                        else if ((result.bfp >= 14) && (result.bfp < 21)) {
                            result.bfpSector = 'athletes';
                            result.bfpSectorTitle = this.$filter('translate')('Low body fat');
                            result.bfpStyleLeft = `${(result.bfp - 14) * 100 / (21 - 14)}%`;
                        }
                        else if ((result.bfp >= 21) && (result.bfp < 25)) {
                            result.bfpSector = 'fitness';
                            result.bfpSectorTitle = this.$filter('translate')('Moderate body fat');
                            result.bfpStyleLeft = `${(result.bfp - 21) * 100 / (25 - 21)}%`;
                        }
                        else if ((result.bfp >= 25) && (result.bfp < 32)) {
                            result.bfpSector = 'average';
                            result.bfpSectorTitle = this.$filter('translate')('Elevated body fat');
                            result.bfpStyleLeft = `${(result.bfp - 25) * 100 / (32 - 25)}%`;
                        }
                        else if (result.bfp >= 32) {
                            result.bfpSector = 'obese';
                            result.bfpSectorTitle = this.$filter('translate')('Obesity');
                            result.bfpStyleLeft = `${(result.bfp - 32) * 100 / (100 - 32)}%`;
                        }
                    }
                }
            }
            catch (e) {
                this.result = { error: this.$filter('translate')('The value you entered is not valid') };
                console.error(e);
            }
            if (result.bfp >= 0) {
                result.bfpTable = this.bfpTable.map((item) => {
                    const range = item[this.values.gender];
                    return {
                        current: result.bfpSector == item.key,
                        title: item.title,
                        currentTitle: range[1] < 100 ? `${range[0]}-${range[1]}%` : `${range[0]}%`,
                        //maleTitle: item.male[1] < 100 ? `${item.male[0]}–${item.male[1]}%` : `${item.male[0]}%`,
                        //femaleTitle: item.female[1] < 100 ? `${item.female[0]}–${item.female[1]}%` : `${item.female[0]}%`,
                        maleTitleStart: item.male[0],
                        maleTitleEnd: item.male[1] < 100 ? item.male[1] : null,
                        femaleTitleStart: item.female[0],
                        femaleTitleEnd: item.female[1] < 100 ? item.female[1] : null,
                    };
                });
                const _w1 = (this.unit == 'metric' ? this.values.weight : this.values.weight_pound);
                result.fm = result.bfp / 100 * _w1;
                result.lm = _w1 - result.fm;
                result.jpIdeal = undefined;
                for (let item of this.jpIdealTable) {
                    if ((this.values.age >= item.age[0]) && (this.values.age < item.age[1])) {
                        const _percent = (this.values.age - item.age[0]) * 100 / (item.age[1] - item.age[0]);
                        const values = this.values.gender == 'male' ? item.male : item.female;
                        result.jpIdeal = values[0] + ((values[1] - values[0]) * _percent / 100);
                        break;
                    }
                }
                this.result = result;
            }
            else {
                this.result = { error: this.$filter('translate')('The value you entered is not valid') };
            }
        }
    }
    getWeightUnit() {
        return this.unit == 'metric' ? this.$filter('translate')('kg') : this.$filter('translate')('lb');
    }
    clear($form) {
        if ($form != undefined) {
            $form.$setPristine();
            $form.$setUntouched();
        }
        this.values = Object.assign({}, defaultValues);
        this.result = undefined;
    }
}
BodyfatcalculatorCtrl.$inject = ['$scope', '$filter'];
const appModule = ng.module('app');
appModule.component('gameBodyfatcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: BodyfatcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('bodyfatcalculator/');
    }]);
